import React from 'react';

import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';

const AutomationBanner = () => {
  const { tunable } = useStaticQuery(graphql`
    query {
      tunable: file(relativePath: { eq: "tunable.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div>
      <div className="box-container">
        <div className="row h-500 h-md-550 px-3">
          <div className="col-12 col-lg-6 text-center">
            <p className="py-1  pt-3">
              <em>
                “Control and monitor your security system
                <br className="d-none d-sm-block" />
                remotely anywhere”
              </em>
            </p>

            <div className="py-2 p-lg-4">
              Never forget to arm your home again. Remotely arm your house and
              remotely monitor your house anywhere with our GANEN mobile APP.
              You can also arm your home with your home automation command, or
              simply arm it when you say “I’m Leaving”.
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <BackgroundImage
              fluid={tunable.childImageSharp.fluid}
              className="h-400 h-md-270 w-md-75 mx-auto"
            ></BackgroundImage>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutomationBanner;
