import React from 'react';

const IntroButton = ({ text, onclick }) => {
  return (
    <h5 className="col-12 col-md-4 mb-3">
      <a
        href="/#"
        onClick={onclick}
        className="ganen-bold text-black text-decoration-none"
      >
        {text}
      </a>
    </h5>
  );
};

const Intro = ({
  intro,
  title1,
  onclick1,
  title2,
  onclick2,
  title3,
  onclick3,
}) => {
  return (
    <section>
      <div className="box-container text-center py-3 py-md-5">
        <h2 className="fs-42 ganen-green-bold py-3">{intro}</h2>
        <div className="row py-2 fs-20">
          <IntroButton text={title1} onclick={onclick1} />
          <IntroButton text={title2} onclick={onclick2} />
          <IntroButton text={title3} onclick={onclick3} />
        </div>
      </div>
    </section>
  );
};

export default Intro;
