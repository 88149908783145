const getWindowDimensions = () => {
  if (window && window !== null) {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  return {
    width: 0,
    height: 0,
  };
};

export default getWindowDimensions;
