import React from 'react';

import PropTypes from 'prop-types';

const ContentTitle = ({ title, bgColor }) => {
  return (
    <div style={{ backgroundColor: `${bgColor}` }}>
      <h3 className="box-container text-center py-3">
        <span className="fs-35 ganen-green-bold">{title}</span>
        <span className="fs-55 ganen-brown-bold">.</span>
      </h3>
    </div>
  );
};

ContentTitle.propTypes = {
  title: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
};

export default ContentTitle;
