import React from 'react';

import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';

const DimmableBanner = () => {
  const { reception } = useStaticQuery(graphql`
    query {
      reception: file(relativePath: { eq: "reception.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className="bg-ganen-gray-2">
      <div className="box-container">
        <div className="row h-500 h-md-550 px-3">
          <div className="col-12 col-lg-6">
            <BackgroundImage
              fluid={reception.childImageSharp.fluid}
              className="h-400 h-md-270 w-md-75 mx-auto"
            ></BackgroundImage>
          </div>
          <div className="col-12 col-lg-6 text-center">
            <p className="py-1 pt-3">
              <em>“Wireless system operating on a secured cloud system”</em>
            </p>

            <div className="py-2 p-lg-4">
              GANEN brings you the most advanced security system in HEIMDALL’s
              range of security products, giving you peace of mind with 24/7
              monitoring and notification capabilities. Our customer support and
              service ensures that your security system is always operational.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DimmableBanner;
