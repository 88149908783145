import React from 'react';

import HomeLightingBanner1 from './home-lighting-banner-1';
import HomeLightingBanner2 from './home-lighting-banner-2';
import MerryGoRound from '../../widgets/merry-go-round';

const HomeLightingCarousel = () => {
  const items = [
    {
      key: 'index-1',
      src: '',
      content: <HomeLightingBanner1 />,
    },
    {
      key: 'index-2',
      src: '',
      content: <HomeLightingBanner2 />,
    },
  ];

  return (
    <section>
      <MerryGoRound items={items} fixHeight={500} />
    </section>
  );
};

export default HomeLightingCarousel;
